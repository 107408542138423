
import React from 'react';
import './style.css'
import Primavera from '../assets/primavera.png'
import Dialog from './Dialog'

class Landing extends React.Component {
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            dialog:false,
        };
        this.Close = this.Close.bind(this)
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    Close(){
        this.setState({dialog:false})
    }
    render() {
        const oscuro = 'rgba(25,25,25,0.6)'
        const h = window.innerHeight;
        console.log(window.innerWidth)
        if(window.innerWidth < 850 ){
            return (
                <div className='bodyc' style={{height:1000,width:'100%'}}>
                    <div style={{height:'100%',display:'flex',flexDirection:'column'}}>
                        <div  style={{ width:'100%',height:'50%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        </div>
                        <div  style={{ width:'100%',height:'50%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <div >
                                <div className='title' style={{ width:'100%',height:'100%',display:'flex',flexDirection:'column'}}> 
                                    <p className='llegaLaEstacionTitle'>LLEGA LA ESTACIÓN MÁS LINDA DEL AÑO Y LA VAMOS A CELEBRAR</p>
                                    <p  className='footerLabelLanding'>ESTE VIERNES PODRÁS VER TU PLAYLIST</p>

                                    {/* <a className='buttonPlay'><span className='buttonLabel'>LET´S PLAY</span></a> */}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <Dialog dialog={this.state.dialog} onClose={() => {this.Close()}}></Dialog>
                </div>
            );
        }else{
            return(
            <div className='bodyc' style={{height:h}}>
                <div className='simple_form' style={{height:h-10,flexDirection:'column'}}>
                    <div className="left" style={{ display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <img className="imagePrimavera" src={Primavera}  />
                    </div>
                    <div className="right">
                        <div className="container">
                            <div className='title' style={{ width:'100%',height:'100%',display:'flex',flexDirection:'column'}}> 
                                <p className='llegaLaEstacionTitle'>LLEGA LA ESTACIÓN MÁS LINDA DEL AÑO Y LA VAMOS A CELEBRAR</p>
                                <p  className='footerLabelLanding'>ESTE VIERNES PODRÁS VER TU PLAYLIST</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <Dialog dialog={this.state.dialog} onClose={() => {this.Close()}}></Dialog>
            </div>
            )
        }
    }
}

export default Landing;