
import React from 'react';
import './style.css'
import Dialog from './Dialog'
import logo from '../assets/logo_blanco.png'
import logoOtro from '../assets/logo_otro.png'

import pibe from '../assets/pibe.png'
import enjoy from '../assets/logo_Enjoy.png'
import konect from '../assets/logo_konecta.png'

const axios = require('axios');
//comment
// coments
class RequestPin extends React.Component {
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            dialog: false,
        };
        this.Close = this.Close.bind(this)
        this.validate = this.validate.bind(this)

    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    Close() {
        this.setState({ dialog: false })
    }

    validate() {
        var self = this;
        //https://z6f1fnfh97.execute-api.us-east-1.amazonaws.com/V1/getpin?pin=BC4610951920
        //'https://api.gameandmusic.com.ar:3001/getPIN?pin='
        axios.get('https://t78pupjmak.execute-api.us-east-1.amazonaws.com/prod/pin?pin=' + this.state.text)
            .then(function (response) {
                // handle success
                return response;
            })
            .catch(function (error) {
                // handle error
                self.setState({ dialog: true })
            })
            .then(function (response) {
                if (response && response.data) {
                    localStorage.setItem("playlist", JSON.stringify(response.data));
                    document.location.href = "/game";
                } else {
                    self.setState({ dialog: true })
                }
            });
    }
    openTerms = () => {
        window.open("https://dev2370.d2kwv40o2mk5ma.amplifyapp.com", "_blank");
    }
    //<img src={logo} style={{ marginTop: 10, position: 'aboslute', top: 20, left: 0, width: 250, height: 250*0.95, backgroundColor: 'transparent', textAlign: 'center' }} />

    render() {
        const oscuro = 'rgba(25,25,25,0.6)'
        const h = window.innerHeight;
        console.log(window.innerWidth)
        if (window.innerWidth < 850) {
            return (
                <div className='bodycResponsive' style={{ height: h, width: window.innerWidth }}>
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className='title' style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>

                            <div style={{  bottom: 20, position: 'absolute', left: 10 }}>
                               {/* <img src={logoOtro} style={{ bottom: 10, position: 'aboslute', right: 50, width: 130, height: 100*0.79}} /> */}
                            </div>


                                <div className='title' style={{ marginTop: 300, width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <p className='titleLabel'>Ingresá tu PIN y visualizá tu cartón</p>
                                    <div style={{ width: '70%', height: 35, borderRadius: 20, borderStyle: 'solid', borderColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 0, boxShadow: ' 4px 4px 6px 0 #bf0352' }}>
                                        <input
                                            placeholder={'Ej. 35293992'}
                                            type={'text'}
                                            onChange={(e) => this.setState({ text: e.target.value })}
                                            style={{ backgroundColor: oscuro, width: '100%', height: '100%', borderStyle: 'none', color: 'white', fontFamily: 'URWGeometricRegular', fontSize: 16, paddingLeft: 10, paddingRight: 10, borderRadius: 24, boxShadow: 'inset 2px 2px 6px 0 #bf0352' }}>
                                        </input>
                                    </div>
                                    {/* <a className='buttonPlay'><span className='buttonLabel'>LET´S PLAY</span></a> */}

                                    <button onClick={() => this.validate()} style={{ height: 50, marginTop: 30, borderRadius: 20, borderStyle: 'solid', borderWidth: 2, borderColor: '#bf0352', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ fontSize: 22, color: 'white', padding: 50, fontWeight: 'bold', fontFamily: 'URWGeometricHeavy', letterSpacing: 2, margin: 0 }}>CONFIRMAR</p>
                                    </button>
                                    <div style={{marginTop:10}}/>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Dialog dialog={this.state.dialog} onClose={() => { this.Close() }}></Dialog>
                </div>
            );
        } else {
            return (
                <div className='bodyc' style={{ height: h }}>
                    <div className='simple_form' style={{ height: h - 10, flexDirection: 'column' }}>
                        <div className="left" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        </div>
                        <div className="right">
                            <div className="container">
                                <div className='title' style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <a onClick={this.openTerms} style={{ color: 'white', fontSize: 20, fontFamily: '', }}>Conocé como participar</a>
                                    <a onClick={this.openTerms} style={{ color: 'white', fontSize: 20, fontFamily: '', }}>Hace click aquí</a>

                                    <p className='titleLabel'>Ingresá tu DNI y visualizá tu cartón</p>
                                    <div style={{ width: '70%', height: 35, borderRadius: 25, borderStyle: 'solid', borderColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 0, boxShadow: ' 4px 4px 6px 0 #bf0352' }}>
                                        <input
                                            placeholder={'Ej. ABC009876125'}
                                            type={'text'}
                                            onChange={(e) => this.setState({ text: e.target.value })}
                                            style={{ backgroundColor: oscuro, width: '100%', height: '100%', borderStyle: 'none', color: 'white', fontFamily: 'URWGeometricRegular', fontSize: 16, paddingLeft: 10, paddingRight: 10, borderRadius: 24, boxShadow: 'inset 2px 2px 6px 0 #bf0352' }}>
                                        </input>
                                    </div>
                                    {/* <a className='buttonPlay'><span className='buttonLabel'>LET´S PLAY</span></a> */}

                                    <button onClick={() => this.validate()} style={{ width: '40%', height: 50, marginTop: 30, borderRadius: 30, borderStyle: 'solid', borderWidth: 2, borderColor: '#bf0352', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ fontSize: 22, color: 'white', fontWeight: 'bold', fontFamily: 'URWGeometricHeavy', letterSpacing: 2, margin: 0 }}>CONFIRMAR</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Dialog dialog={this.state.dialog} onClose={() => { this.Close() }}></Dialog>
                </div>
            )
        }

    }


}

export default RequestPin;