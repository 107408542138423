import React from 'react';
import logo from './logo.svg';
import RequestPin from './components/RequestPin.js'
import PlayGame from './components/PlayGame.js'
import Landing from './components/Landing.js'
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' exact component={RequestPin} />
        <Route path='/game' exact component={PlayGame} />
      </Switch>
    </BrowserRouter>);
}

export default App;
